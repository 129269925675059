import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='რატომ ინგლისური?' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="რატომ ჩვენ?"
          // subheader='შექმენი შენი მომავალი'
        />

        <Section>
          <Card variant='paper'>
            <Text variant='p'>
            - ელემენტების შეკეთება
            </Text>
            <Text variant='p'>
            - ელემენტების დიაგნოსტიკა
            </Text>
            <Text variant='p'>
            - ახალი და მეორადი ელემენტების ფართო არჩევანი
            </Text>
      
            </Card>
        </Section>

        <Section>
          <Card variant='paper'>
          <Text variant='p'>
          ჩვენთან თქვენ შეძლებთ:
            </Text>
            <Text variant='p'>
            - შეიძინოთ ელექტრო და ჰიბრიდული ავტომობილების ელემენტები
            </Text>
            <Text variant='p'>
            - შეაკეთოთ ან აღადგინოთ ელემენტები
            </Text>
            <Text variant='p'>
            - შეამოწმოთ ელემენტები
            </Text>
      
          </Card>
        </Section>



        <Divider />
        {/* <ContactForm /> */}
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
