import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='eAuto.ge' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      მიიღე ხარისხიანი და სწრაფი სერვისი
      </Text>
      <Text variant='p'>
      - შეამოწმე შენი ელემენტები
      </Text>
      <Text variant='p'>
      - შეაკეთე ელემენტები სწრაფად
      </Text>
    </Card>
  </Section>
)

export default Commitment
